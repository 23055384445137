@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;600;700&display=swap");
.App {
  text-align: center;
}

body,
html {
  margin: 0;
  min-width: 100%;
  max-width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f4f6fa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-input-number .ant-input-number-input {
  text-align: end;
}

.table-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-icon {
  font-size: 19px;
  .row-match {
    background-color: #deebfa;
  }
}
